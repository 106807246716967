import { useCallback, useEffect, useState } from "react"
import { useCore } from "./useCore"

export const useEmarsys = () => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const [emarsysLoaded, setEmarsysLoaded] = useState(typeof window !== `undefined` ? !!window.ScarabQueue : false)

  const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

  useEffect(() => {
    if (emarsysLoaded) {
      return
    }

    // Poll until Emarsys is loaded via GTM
    const interval = setInterval(() => {
      if (window.ScarabQueue) {
        setEmarsysLoaded(true)
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  const track = (id, payload = null) => {
    const arr = [id]
    if (payload) {
      arr.push(payload)
    }

    if (window.ScarabQueue) {
      if (isDev) {
        console.log(`[Emarsys] ${id} fired\n${JSON.stringify(payload)}`)
      }
      window.ScarabQueue.push(arr)
    } else {
      if (isDev) {
        console.log(`[Emarsys] ${id} fired (ScarabQueue missing)\n${JSON.stringify(payload)}`)
      }
    }
  }

  const identify = useCallback(customer => {
    track("setEmail", `${customer?.email}`)
  }, [])

  const trackCart = useCallback(
    lines => {
      try {
        if (!lines) {
          lines = []
        }

        track(
          "cart",
          lines.map(item => ({
            item: decodeShopifyId(item?.merchandise?.id, "ProductVariant"),
            price:
              parseFloat(item?.merchandise?.priceV2?.amount) * item.quantity -
              item.discountAllocations.reduce((sum, cur) => sum + Number(cur?.discountedAmount?.amount), 0),
            quantity: item.quantity,
          }))
        )
      } catch (e) {
        console.log(e)
      }
    },
    [decodeShopifyId]
  )

  const trackProductView = useCallback(productId => {
    try {
      track("view", `g/${productId}`)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const trackCategory = useCallback(collection => {
    track("category", collection.title)
  }, [])

  const trackGo = useCallback(() => {
    track("go")
  }, [])

  const trackPage = useCallback((customer, cart, collection = null, productId = null) => {
    if (customer) {
      identify(customer)
    }
    trackCart(cart?.lines)
    if (productId) {
      trackProductView(productId)
    }
    if (collection) {
      trackCategory(collection)
    }
    trackGo()
  }, [])

  return { emarsysLoaded, identify, trackCart, trackProductView, trackCategory, trackGo, trackPage }
}
