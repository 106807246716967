import React, { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../../../hooks/useFunctions"
import { useCartContext } from "../../../../providers/cart"
import { useCustomerContext } from "../../../../hooks/useCustomer"
import { useRoutes } from "../../../../hooks/useRoutes"
// import { useShopify } from "../../../../hooks/useShopify"
import { useApp } from "../../../../hooks/useApp"

export const withCheckout = Component => ({ name = "Checkout" }) => {
  const { customer } = useCustomerContext()
  const { cart: cartContext, gotoCheckout, giftRegistryCheckoutHandler } = useCartContext()
  const { loading } = useFunctions()
  const { linkResolver } = useRoutes()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_CHECKOUT {
      cart: sanityPageCart {
        #freeShippingThreshold,
        #freeShippingCallToActionMessage,
        continueShopping: _rawContinueShopping(resolveReferences: { maxDepth: 2 })
        shippingNotes
        additionalCheckoutButton
        additionalCheckoutButtonLoading
        additionalKeepShoppingButton
      }
    }
  `)

  const {
    // freeShippingThreshold,
    // freeShippingCallToActionMessage,
    continueShopping,
    shippingNotes,
    additionalCheckoutButton,
    additionalCheckoutButtonLoading,
    additionalKeepShoppingButton,
  } = cart || {}

  const { globalStateReducer } = useApp()

  const [, dispatch] = globalStateReducer

  const handleClose = useCallback(() => dispatch({ type: "HIDE_CART" }), [dispatch])

  const handleCheckout = useCallback(
    async event => {
      event.preventDefault()
      // await checkoutMultipass(customer?.email, cartContext?.checkoutUrl)
      const giftRegistryCheckout = await giftRegistryCheckoutHandler()
      gotoCheckout(event, giftRegistryCheckout?.addressPrefill, giftRegistryCheckout?.registryItemsInCart)

    },
    [gotoCheckout, giftRegistryCheckoutHandler, cartContext?.checkoutUrl, customer?.email]
  )

  // const freeShippingAmount = freeShippingThreshold - checkout?.paymentDueV2?.amount

  // const freeShippingCallToAction = freeShippingAmount > 0 ? freeShippingCallToActionMessage.replace("${amount}", formatMoney(freeShippingAmount)) : ""

  const continueButton = linkResolver(continueShopping)

  Component.displayName = name
  return (
    <Component
      checkoutUrl={cartContext?.checkoutUrl}
      loading={loading}
      handleCheckout={handleCheckout}
      continueButton={continueButton}
      shippingNotes={shippingNotes}
      additionalCheckoutButton={additionalCheckoutButton}
      additionalCheckoutButtonLoading={additionalCheckoutButtonLoading}
      additionalKeepShoppingButton={additionalKeepShoppingButton}
      handleClose={handleClose}
    />
  )
}
