import React, { useEffect, useState } from "react"
import { useCartContext } from "../../../providers/cart"
export const withPrice = Component => ({ name = "Price", variant, layout, isFreeGift, discountAllocationsAmount, staticPrice = false }) => {
  const { cart, currencyCode } = useCartContext()

  const [price, setPrice] = useState("")
  const [compareAtPrice, setCompareAtPrice] = useState("")

  useEffect(() => {
    if (currencyCode) {
      const presentmentPrice = variant?.presentment_prices?.find(price => price.price.currency_code === currencyCode)
      const basePrice = presentmentPrice?.price?.amount || variant?.priceV2?.amount || variant?.priceV2 || variant?.price || 0
      const compareAtBasePrice =
        presentmentPrice?.compare_at_price?.amount ||
        variant?.compareAtPriceV2?.amount ||
        variant?.compareAtPriceV2 ||
        variant?.compareAtPrice ||
        variant?.compare_at_price ||
        0
      const price = discountAllocationsAmount ? basePrice - discountAllocationsAmount : basePrice
      const compareAtPrice = discountAllocationsAmount || compareAtBasePrice

      setPrice(price)
      setCompareAtPrice(compareAtPrice)
    }
  }, [currencyCode, discountAllocationsAmount, variant])

  const formattedPrice = price ? Number(price) : ""
  const formattedComparedAtPrice = compareAtPrice ? Number(compareAtPrice) : ""

  const isOnSale = formattedComparedAtPrice > formattedPrice

  Component.displayName = name
  return (
    <Component
      isOnSale={isOnSale}
      price={formattedPrice}
      compareAtPrice={formattedComparedAtPrice}
      layout={layout}
      isFreeGift={isFreeGift}
      staticPrice={staticPrice}
    />
  )
}
