import { useState, useCallback } from "react"

import { useCore } from "./useCore"
import { useLocation } from "./useLocation"
import { useApp } from "./useApp"
import { useCustomerContext } from "./useCustomer"

export const useFunctions = () => {
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { shopifyStore } = useLocation()
  const {
    config: {
      services: { functions, firebase },
    },
  } = useApp()

  const { customer, setCustomer } = useCustomerContext()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const requestGatsby = useCallback(async (endpoint, data) => {
    return fetch(`/api/${endpoint}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Max-Age": "3600",
      },
      body: JSON.stringify(data),
    }).then(res => res.json())
  }, [])

  const request = useCallback(
    async (endpoint, data) => {
      setLoading(true)
      setErrors([])
      const localFirebaseEndpoint = "http://localhost:8000/" + firebase.projectId + "/" + firebase.region
      const baseEndpoint = "development" === process.env.NODE_ENV ? localFirebaseEndpoint : functions?.endpoint

      data.shop = shopifyStore

      return fetch(`${baseEndpoint}${endpoint}?shop=${shopifyStore}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Max-Age": "3600",
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false)
          if (result.status == "error") {
            setErrors(result?.body)
          }
          return result
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          return error
        })
    },
    [setLoading, setErrors, shopifyStore, functions]
  )

  const customerSubscribe = useCallback(
    async (email, tags = []) => {
      const { customer } = await request("/customer/subscribe", { email, tags })
      return { customer }
    },
    [request]
  )

  const customerTags = useCallback(
    async tags => {
      const id = decodeBase64(customer?.id)
      const { userErrors } = await request("/customer/tags", { id, tags })
      if (!userErrors?.length) setCustomer(prevState => ({ ...prevState, tags }))
    },
    [customer, decodeBase64, request, setCustomer]
  )

  const customerMultipass = useCallback(
    async firebaseToken => {
      const { multipassToken } = await request("/customer/multipass", { firebaseToken })
      return { multipassToken }
    },
    [request]
  )

  const checkoutMultipass = useCallback(
    async (customerEmail, webUrl) => {
      if (customerEmail) {
        const response = await request("/checkout/multipass", { customerEmail, webUrl })
        const url = response?.status !== "error" && response?.includes("https://") ? response : webUrl
        window.location.replace(url)
      } else {
        window.location.replace(webUrl)
      }
    },
    [request]
  )

  const formSubmission = useCallback(
    async (form, data, ipData, userAgent) => {
      const id: string = form?.id
      const type: string = form?.types[data?.type?.toLowerCase()]?.title.toLowerCase()
      const subject: string = form?.subject || `Form submission from ${form?.title}`
      const { status, body } = await request("/form/submission", { id, type, subject, data, ipData, userAgent })
      return { status, body }
    },
    [request]
  )

  const getGiftcard = useCallback(
    async args => {
      const { number, pin } = args

      // const resp = await request("/getGiftcard", { number, pin })
      const resp = await requestGatsby("getGiftcard", { number, pin })

      return resp
    },
    [requestGatsby]
  )

  const backInStock = useCallback(
    async (email, variant, product, url) => {
      const { success } = await request("/form/backinstock", { email, variant, product, url })
      return success
    },
    [request]
  )


  const callFunction = useCallback(async (name, data) => {
    try {
      setLoading(true)
      setErrors([])
      const response = await fetch(`/api/${name}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      const body = await response.json()
      setLoading(false)
      return { ...body }
    } catch (err) {
      setLoading(false)
      setErrors([(err as Error).message])
      return { status: "error", body: (err as Error).message }
    }
  }, [])

  return { customerSubscribe, customerTags, customerMultipass, getGiftcard, checkoutMultipass, formSubmission, callFunction, backInStock, loading, errors }
}
