import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCart } from "../../../hooks/useCart"

export const withCartItem = Component => ({ name = "CartItem", item, layout = "" }: any) => {
  const { loading: cartLoading, loadingRemove, removeFromCart, updateQuantity } = useCart()

  const { cart, giftcard } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_ITEM {
      cart: sanityPageCart {
        additionalRemoveLineItemButtonText
        additionalRemovingLineItemButtonText
        additionalInventoryWarning
        additionalPreOrderText
      }
      giftcard: sanityPageGiftcard {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
        additionalGiftCardMessageLabel
        additionalGiftCardSenderLabel
        additionalGiftCardReceiverLabel
        additionalGiftCardReceiverEmailLabel
      }
    }
  `)

  const { additionalRemoveLineItemButtonText, additionalRemovingLineItemButtonText, additionalInventoryWarning, additionalPreOrderText } = cart || {}

  const {
    settingGiftCardProduct,
    additionalGiftCardSenderLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardMessageLabel,
    additionalGiftCardReceiverEmailLabel,
  } = giftcard || {}

  const {
    config: {
      settings: {
        giftCardConstraints,
        routes: { PRODUCT, GIFTCARD },
        keys: { preOrderKey, preOrderValue },
      },
    },
  } = useApp()

  const [inputQuantity, setInputQuantity] = useState(item?.quantity)

  const [warning, setWarning] = useState("")

  const isPreOrder = item?.attributes?.find(attribute => attribute.key === preOrderKey)?.value === preOrderValue

  const handleQuantity = quantity => {
    setInputQuantity(quantity)
    if (quantity > 0 && (quantity <= item?.merchandise?.quantityAvailable || isPreOrder)) {
      updateQuantity(item?.id, item?.merchandise?.id, quantity)
      setWarning("")
    } else {
      setWarning(additionalInventoryWarning.replace("${amount}", item?.merchandise?.quantityAvailable))
    }
  }

  const handleRemove = () => removeFromCart(item?.id, item?.merchandise?.id)

  const handleChange = ({ target: { value } }) => {
    const quantity = parseInt(value, 10)
    setInputQuantity(quantity)
    if (quantity > 0 && (quantity <= item?.variant?.quantityAvailable || isPreOrder)) {
      updateQuantity(item?.id, item?.merchandise?.id, quantity)
      setWarning("")
    } else {
      setWarning(additionalInventoryWarning.replace("${amount}", item?.merchandise?.quantityAvailable))
    }
  }

  // intentionally ignore inputQuantity in the dependencies, this hook will only run when cart item quantity change.
  useEffect(() => {
    if (item?.quantity !== inputQuantity) {
      setInputQuantity(item?.quantity)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.quantity, inputQuantity])

  const isGiftCard = item?.merchandise?.product?.handle === settingGiftCardProduct?.shopify?.handle

  const url = isGiftCard ? GIFTCARD : `${PRODUCT}/${item?.merchandise?.product?.handle}`

  const cartMessages = item?.merchandise?.product?.tags?.filter(tag => tag.includes("cart:")).map(tag => tag.replace("cart:", ""))

  const cartPromotionMessages = item?.attributes.find(item => item.key === "_appliedPromotionRules")?.value?.split(",")
  const discountMessage = [
    ...(cartPromotionMessages ? cartPromotionMessages : []),
    ...item?.discountAllocations?.map(discount => discount?.discountApplication?.title),
  ].filter(message => message !== "Promotional discount")

  const discountAllocationsAmount = item?.discountAllocations?.reduce((acc, cur) => acc + Number(cur.discountedAmount.amount), 0) / item?.quantity
  const isFreeGift = item?.attributes.find(item => item.key === "_promotionalFreeGift")?.value?.split(",")

  Component.displayName = name
  return (
    <Component
      layout={layout}
      item={item}
      url={url}
      loading={cartLoading}
      loadingRemove={loadingRemove}
      handleQuantity={handleQuantity}
      handleChange={handleChange}
      inputQuantity={inputQuantity}
      warning={warning}
      isPreOrder={isPreOrder}
      handleRemove={handleRemove}
      additionalRemoveLineItemButtonText={additionalRemoveLineItemButtonText}
      additionalRemovingLineItemButtonText={additionalRemovingLineItemButtonText}
      additionalPreOrderText={additionalPreOrderText}
      additionalGiftCardSenderLabel={additionalGiftCardSenderLabel}
      additionalGiftCardMessageLabel={additionalGiftCardMessageLabel}
      additionalGiftCardReceiverLabel={additionalGiftCardReceiverLabel}
      additionalGiftCardReceiverEmailLabel={additionalGiftCardReceiverEmailLabel}
      giftCardConstraints={giftCardConstraints}
      isGiftCard={isGiftCard}
      cartMessages={cartMessages}
      discountMessage={discountMessage}
      discountAllocationsAmount={discountAllocationsAmount}
      isFreeGift={isFreeGift}
    />
  )
}
