import React from "react"

import { LocationProvider } from "./location"
import { FirebaseProvider } from "./firebase"
import { ShopifyProvider } from "./shopify"
import { AppProvider } from "./app"
import { TrackingProvider } from "./tracking"
import { CustomerProvider } from "./customer"
import { WishlistProvider } from "./wishlist"
import { ShopProvider } from "./shop"
import config from "../../config.default"
import { NavProvider } from "./nav"
import { PopupsProvider } from "./popups"
import { CartProvider } from "./cart"
import { ConfigProvider } from "./config"

export const Providers = ({ children }) => {
  return (
    <LocationProvider config={config}>
      <ConfigProvider>
        <ShopifyProvider
          config={{
            shopName: config?.services?.shopify?.defaultShopName,
            apiVersion: config?.services?.shopify?.apiVersion,
            accessToken: config?.stores[config?.services?.shopify?.defaultShopName]?.accessToken,
          }}
          allStores={config.stores}
        >
          <FirebaseProvider config={config?.services?.firebase}>
            <AppProvider config={config}>
              <ShopProvider>
                <CustomerProvider>
                    <CartProvider>
                      <WishlistProvider>
                        <TrackingProvider>
                          <PopupsProvider>
                            <NavProvider>{children}</NavProvider>
                          </PopupsProvider>
                        </TrackingProvider>
                      </WishlistProvider>
                    </CartProvider>
                </CustomerProvider>
              </ShopProvider>
            </AppProvider>
          </FirebaseProvider>
        </ShopifyProvider>
      </ConfigProvider>
    </LocationProvider>
  )
}
