import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useShopify } from "../../../../hooks/useShopify"
import { useCart } from "../../../../hooks/useCart"
import { useCartContext } from "../../../../providers/cart"

export const withSummary = Component => ({ name = "Summary" }) => {
  const { cart:cartContext, currencyCode } = useCartContext();
  const { applyDiscountCode } = useCart();

  const { formatMoney, edgeNormaliser } = useShopify()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_SUMMARY {
      cart: sanityPageCart {
        #freeShippingThreshold,
        additionalSubtotalText
        additionalDiscountText
        additionalShippingText
        additionalShippingPlaceholderText
        additionalTotalText
      }
    }
  `)

  const {
    // freeShippingThreshold,
    additionalSubtotalText,
    additionalDiscountText,
    additionalShippingText,
    additionalShippingPlaceholderText,
    additionalTotalText,
  } = cart || {}

  // Total
  const amountDue = formatMoney(cartContext?.cost?.totalAmount?.amount)

  // Subtotal
  const subTotal = formatMoney(cartContext?.lines?.reduce((acc, line) => {
    return acc + Number(line?.cost?.subtotalAmount?.amount)
  }, 0))

  const shippingText = additionalShippingPlaceholderText

  const lineDiscountAllocation = cartContext?.lines?.reduce((acc, line) => {
    let lineDiscount = 0;
    if (line?.discountAllocations?.length) {
      line?.discountAllocations.forEach((discount) => {
        lineDiscount += discount?.__typename === 'CartCodeDiscountAllocation' ? parseFloat(discount?.discountedAmount?.amount) : 0
      })
    }
    return acc + lineDiscount
  }, 0)
  
  // Discount
  const appliedDiscountCode = cartContext?.discountCodes?.[0]?.code

  const discountApplied = 0 + lineDiscountAllocation + (appliedDiscountCode ? cartContext?.discountAllocations.reduce((acc, allocation) => (
    acc + parseFloat(allocation?.discountedAmount?.amount || 0)
  ), 0) : 0)

  const formattedDiscountApplied = React.useMemo(() => {
    return formatMoney(discountApplied)
  }, [discountApplied, formatMoney])


  const handleDiscountCodeRemove = () => applyDiscountCode('')

  Component.displayName = name
  return (
    <Component
      amountDue={amountDue}
      subTotal={subTotal}
      shippingText={shippingText}
      discountApplied={discountApplied}
      formattedDiscountApplied={formattedDiscountApplied}
      appliedDiscountCode={appliedDiscountCode}
      handleDiscountCodeRemove={handleDiscountCodeRemove}
      currencyCode={currencyCode}
      additionalSubtotalText={additionalSubtotalText}
      additionalDiscountText={additionalDiscountText}
      additionalShippingText={additionalShippingText}
      additionalShippingPlaceholderText={additionalShippingPlaceholderText}
      additionalTotalText={additionalTotalText}
    />
  )
}
