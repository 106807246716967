import { DotHeadless } from "@dotdev/dotheadless-app"
import { DotHeadless as LocalDotHeadless } from "./../dotheadless"
import { useConfigContext } from "./../providers/config"
import { sanityContent } from "../helpers/sanity"

export const useCore = () => {
  return {
    ...DotHeadless,
    ...LocalDotHeadless,
    helpers: {
      ...DotHeadless.helpers,
      sanityContent,
    },
  }
}

export const useStorage = () => {
  const { store } = useConfigContext
  // const { store } = useLocationContext() as StoreLocation
  const storeId = store?.id

  const getStorage = (key: string) => DotHeadless.helpers.storage.get(`${storeId}:${key}`)

  const setStorage = (key: string, value: any, expiryDays?: number) =>
    DotHeadless.helpers.storage.set(`${storeId}:${key}`, value, expiryDays || undefined)

  const removeStorage = (key: string) => DotHeadless.helpers.storage.remove(`${storeId}:${key}`)

  return { getStorage, setStorage, removeStorage }
}
