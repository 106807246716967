import React, { useState, useMemo, useCallback, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { graphql, useStaticQuery } from "gatsby"
import { useCore, useStorage } from "./../hooks/useCore"
import { useShopify } from "./../hooks/useShopify"
import { useConfigContext } from "./../providers/config"
import { useSale } from "../hooks/useSale"
import { useCustomerContext } from "../hooks/useCustomer"
import { useFunctions } from "../hooks/useFunctions"


type ContextProps = {
  id: string
  count: number
  cart: any
  currencyCode: string
  saveCart: (cart: any) => void
  createCart: (countryCode: string | undefined, forceNew: boolean) => void
  giftRegistryCheckoutHandler: () => Promise<any>
  gotoCheckout: any
  livePromotionRules: any
  giftcardHandle: string
  freeGiftSets:any 
  setFreeGiftSets:any
  initializedLoading: boolean
}

export const CartContext = React.createContext<ContextProps | undefined>(undefined)

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    graphql: {
      mutations: { CART_CREATE },
      queries: { 
        GET_CART
      },
    },
  } = useCore()

  const { store, settings } = useConfigContext()
  const { customer } = useCustomerContext()

  const { callFunction } = useFunctions()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const { cartNormaliser } = useShopify()
  const { refetch: getCartQuery } = useQuery(GET_CART, { fetchPolicy: "no-cache", skip: true })
  const { edgeNormaliser } = useShopify()
  const { getOnSale } = useSale()

  const [cartCreate] = useMutation(CART_CREATE)
  const [cart, setCart] = useState<any | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [initializedLoading, setInitializedLoading] = useState(true)
  const [freeGiftSets, setFreeGiftSets] = useState<Array<any>>([])


  useEffect(() => {
    createCart(store?.siteLocation || "AU")
  }, [])
  
  const id = useMemo(() => cart?.id || getStorage(settings.keys.cart), [getStorage, settings.keys.cart, cart?.id])

  const currencyCode = useMemo(() => (
    cart?.cost?.totalAmount?.currencyCode && cart.cost.totalAmount.currencyCode !== "XXX" ? cart.cost.totalAmount.currencyCode : "AUD"
  ), [cart?.cost?.totalAmount?.currencyCode])

  const count = useMemo(
    () =>
      cart?.totalQuantity, // cart?.lines?.reduce((count: number, lineItem: any, i: number) => (i ? count + lineItem.quantity : lineItem.quantity), 0) || 0,
    [cart]
  )


  const getCart = useCallback(async () => {
    try {
      if (id) {
        const countryCode = getStorage(settings.keys.market) || store.locationRegion
        setLoading(true)
        const {
          data: { cart },
        } = await getCartQuery({ countryCode, cartId: id })
        setLoading(false)
        return cartNormaliser(cart)
      }
      return false
    } catch (e) {
      setLoading(false)
      console.error((e as Error).message)
    }
  }, [id, settings.keys.market, store.locationRegion, getCartQuery, getStorage])

  const saveCart = useCallback(
    (cart: any) => {
      try {
        const normalisedCart = cartNormaliser(cart)
        const newCart = { ...normalisedCart, lines: normalisedCart.lines }
        setCart(newCart)
        setStorage(settings.keys.cart, newCart.id)
        setStorage(settings.keys.market, newCart.buyerIdentity?.countryCode)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [cartNormaliser, setStorage, settings.keys.cart, settings.keys.market]
  )

  const createCart = useCallback(
    async (countryCode = "AU") => {
      try {
        const existingCart = await getCart()

        if (!existingCart?.id || Object.keys(existingCart).length < 1) {
          const {
            data: {
              cartCreate: { cart },
            },
          } = await cartCreate({
            variables: {
              countryCode,
              input: {
                buyerIdentity: {
                  countryCode,
                },
              },
            },
          })

          if (cart) {
            saveCart(cart)
            setInitializedLoading(false)
          }
        } else {
          saveCart(existingCart)
          setInitializedLoading(false)
        }
      } catch (e) {
        console.error((e as Error).message)
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) removeStorage(settings.keys.cart)
      }
    },
    [getCart, saveCart, cartCreate]
  )

  const gotoCheckout = useCallback(
    async (e?: MouseEvent) => {
      if (e) e.preventDefault()

      if (!cart) return

      if (customer?.email) {
        try {
          const response = await callFunction("checkout-multipass", {
            customerEmail: customer.email,
            checkoutUrl: cart.checkoutUrl,
          })

          const url = response.status !== "error" && response.body.includes("https://") ? response.body : cart.checkoutUrl
          history.pushState(null, "", window.location.href)
          window.location.replace(url)
        } catch (e) {
          history.pushState(null, "", window.location.href)
          window.location.replace(cart.checkoutUrl)
        }
      } else {
        history.pushState(null, "", window.location.href)
        window.location.replace(cart.checkoutUrl)
      }
    },
    [callFunction, cart, customer]
  )

  const giftRegistryCheckoutHandler = useCallback(async () => {
    let registryItemsInCart = false
    let addressPrefill = false

    const hasRegistryProduct = cart?.lines?.filter(item =>
      item.attributes.some(attr => attr.key === "registryProduct" && attr.value === "true")
    )

    if (hasRegistryProduct?.length > 0) {
      registryItemsInCart = true
    }

    if (registryItemsInCart === true) {
      registryItemsInCart = cart?.lines
      if (cart?.lines?.length > hasRegistryProduct?.length) {
        addressPrefill = false
      } else {
        addressPrefill = true
      }
    }

    return { registryItemsInCart, addressPrefill }
  }, [cart])

    const { pageGiftcard, rawPromotions } = useStaticQuery(graphql`
    query SANITY_CHECKOUT {
      pageGiftcard: sanityPageGiftcard {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
      }
      rawPromotions: allSanityPromotion(filter: { active: { eq: true } }) {
        edges {
          node {
            title
            slug {
              current
            }
            promotionRules {
              isTheLast
              promotionTags
              rule: _rawRule(resolveReferences: { maxDepth: 10 })
              exclusions
            }
          }
        }
      }
    }
  `)

  const promotions = rawPromotions
  ? edgeNormaliser(rawPromotions).reduce(
      (result, item) =>
        item?.slug?.current
          ? {
              ...result,
              [item.slug.current]: {
                rules: item?.promotionRules,
              },
            }
          : result,
      {}
    )
  : {}

  const saleName = getOnSale()

  const livePromotionRules = promotions?.[saleName]?.rules || []

  const { settingGiftCardProduct } = pageGiftcard || {}

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      id,
      cart,
      count,
      currencyCode,
      freeGiftSets,
      initializedLoading,
      livePromotionRules,
      giftcardHandle: settingGiftCardProduct?.shopify?.handle,
      saveCart,
      createCart,
      gotoCheckout,
      setFreeGiftSets,
      setInitializedLoading,
      giftRegistryCheckoutHandler
    }),
    [createCart]
  )

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useCartContext = (): ContextProps => ({ ...React.useContext(CartContext) } as ContextProps)