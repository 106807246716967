import React from "react"

import { withCartItem } from "./withCartItem"
import {
  Wrapper,
  Thumbnail,
  Details,
  InnerTitleWrapper,
  InnerTitle,
  OuterTitle,
  QuantityWrapper,
  QuantityButton,
  QuantityNumber,
  Remove,
  SelectedOptions,
  StyledRow,
  Warning,
  CartMessage,
  DiscountMessage,
  FreeGiftMessage,
} from "./CartItemStyles"
import { Image } from "../../Image/Image"
import { Icon } from "../../Icon/Icon"
import { Price } from "../../Product/Price/Price"
import { Link } from "gatsby"
import { PriceTag } from "../../Product/Price/PriceStyles"

export const CartItem = withCartItem(
  ({
    layout,
    item,
    url,
    loading,
    loadingRemove,
    handleQuantity,
    handleChange,
    handleRemove,
    inputQuantity,
    warning,
    isPreOrder,
    additionalRemoveLineItemButtonText,
    additionalRemovingLineItemButtonText,
    additionalPreOrderText,
    additionalGiftCardSenderLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardMessageLabel,
    additionalGiftCardReceiverEmailLabel,
    giftCardConstraints,
    isGiftCard,
    cartMessages,
    discountMessage,
    discountAllocationsAmount,
    isFreeGift,
  }): JSX.Element => (
    <Wrapper layout={layout} loading={loading || loadingRemove ? "true" : "false"}>
      <Thumbnail as={Link} to={url} title={item?.merchandise?.product?.title} image={item?.merchandise?.image?.originalSrc ? "true" : "false"} layout={layout}>
        <Image image={{...item?.merchandise?.image, src: item?.merchandise?.image?.originalSrc }} ratio={"1/1"} />
      </Thumbnail>
      <Details>
        {isFreeGift && <FreeGiftMessage>Gift</FreeGiftMessage>}
        <OuterTitle as={Link} to={url} title={item?.merchandise?.product?.title} layout={layout}>
          {item?.merchandise?.product?.title}
        </OuterTitle>
        <StyledRow>
          <InnerTitleWrapper>
            <InnerTitle as={Link} to={url} title={item?.merchandise?.product?.title} layout={layout}>
              {item?.merchandise?.product?.title}
            </InnerTitle>
            {!isGiftCard ? (
              <SelectedOptions>
                {item?.merchandise?.selectedOptions.map(option =>
                  option.name !== "Size" ? <span key={option.name}>{`${option.name}: ${option.value}`}</span> : null
                )}
                {item?.attributes?.map(option => {
                  return (
                    !option?.key?.startsWith?.("_") && (
                      <span
                        style={{
                          fontWeight: option.key === "Message" ? "bold" : "",
                        }}
                        key={option.name}
                      >
                        {`${option.key}: ${option.value}`}
                      </span>
                    )
                  )
                })}
                {isPreOrder ? <b>{additionalPreOrderText}</b> : null}
              </SelectedOptions>
            ) : (
              <SelectedOptions>
                {item?.attributes?.map((option, idx) => {
                  if (option.value !== "") {
                    if (option.key === giftCardConstraints.SENDER) {
                      return (
                        <span key={idx}>
                          {additionalGiftCardSenderLabel}: {option.value}
                        </span>
                      )
                    }
                    if (option.key === giftCardConstraints.RECEIVER) {
                      return (
                        <span key={idx}>
                          {additionalGiftCardReceiverLabel}: {option.value}
                        </span>
                      )
                    }
                    if (option.key === giftCardConstraints.RECEIVER_EMAIL) {
                      return (
                        <span key={idx}>
                          {additionalGiftCardReceiverEmailLabel}: {option.value}
                        </span>
                      )
                    }
                    if (option.key === giftCardConstraints.MESSAGE) {
                      return (
                        <span key={idx}>
                          {additionalGiftCardMessageLabel}: {option.value}
                        </span>
                      )
                    }
                  }
                  if (option.value !== "" && !option?.key?.startsWith?.("_")) {
                    return (
                      <span key={idx}>
                        {option?.key}: {option.value}
                      </span>
                    )
                  }
                })}
              </SelectedOptions>
            )}
          </InnerTitleWrapper>
          {!isGiftCard && !isFreeGift ? (
            <div>
              <QuantityWrapper>
                <QuantityButton title={"minus"} disabled={loading || item?.quantity === 1} onClick={() => handleQuantity(item?.quantity - 1)}>
                  <Icon name={"minus"} width={10} height={10} />
                </QuantityButton>
                <QuantityNumber disabled={loading} type={"number"} value={inputQuantity} onChange={handleChange} />
                <QuantityButton
                  title={"add"}
                  disabled={loading || (item?.merchandise?.quantityAvailable <= item?.quantity && !isPreOrder)}
                  onClick={() => handleQuantity(item?.quantity + 1)}
                >
                  <Icon name={"plus"} width={10} height={10} />
                </QuantityButton>
              </QuantityWrapper>
            </div>
          ) : null}
        </StyledRow>
        {warning ? (
          <StyledRow>
            <Warning>{warning}</Warning>
          </StyledRow>
        ) : null}
        <StyledRow>
          <Remove disabled={loadingRemove || loading} onClick={handleRemove}>
            <Icon name={"cross"} width={10} height={10} />
            <span>{loadingRemove ? additionalRemovingLineItemButtonText : additionalRemoveLineItemButtonText}</span>
          </Remove>
          {isGiftCard ? (
            <PriceTag layout={layout}>${(item?.merchandise?.priceV2?.amount * item?.quantity).toFixed(2)}</PriceTag>
          ) : (
            <Price
              variant={item?.merchandise}
              layout={layout}
              isFreeGift={isFreeGift}
              discountAllocationsAmount={discountAllocationsAmount}
              staticPrice={true}
            />
          )}
        </StyledRow>
        {cartMessages?.length ? (
          <StyledRow>
            {cartMessages?.map((message, index) => (
              <CartMessage key={index}>{message}</CartMessage>
            ))}
          </StyledRow>
        ) : null}
        {discountMessage?.length > 0 &&
          discountMessage.map(message => {
            return <DiscountMessage key={message}>{message}</DiscountMessage>
          })}
      </Details>
    </Wrapper>
  )
)
