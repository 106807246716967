import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useCartContext } from "../../../providers/cart"

export const withCartWidget = Component => ({ name = "CartTotal" }: any) => {
  const { globalStateReducer } = useApp()
  const { cart } = useCartContext()

  const [{ activeCart }, dispatch] = globalStateReducer

  const handleActiveCart = () => {
    dispatch({
      type: "TOGGLE_CART",
      payload: !activeCart,
    })
  }

  let count = 0
  cart?.lines?.map((item: any) => (item?.merchandise?.product?.title.toLowerCase().includes("gift card") ? (count += 1) : (count += item?.quantity)))

  Component.displayName = name
  return <Component handleActiveCart={handleActiveCart} count={count} />
}
