import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import React, { useEffect } from "react"
import { useNav } from "./useNav"

export const useNavColour = () => {
  const currentRoute = useLocation()

  const [storedRoute, setStoredRoute] = React.useState("")

  const { globalStateReducer: navGlobalStateReducer } = useNav()

  const navDispatch = navGlobalStateReducer?.[1]

  const { settings } = useStaticQuery(graphql`
    query headerNavSettings {
      settings: sanitySettingMenus {
        headerActiveBackgroundColor {
          hex
        }
        headerDefaultLinkColor {
          hex
        }
        headerHoverLinkColor {
          hex
        }
      }
    }
  `)

  // debug
  // settings.headerActiveBackgroundColor.hex = "#FFFFFF"
  // settings.headerDefaultLinkColor.hex = "#FFFFFF"
  // settings.headerHoverLinkColor.hex = "#000000"

  const defaultOrFlipped = React.useCallback(
    (color1, color2) => {
      if (currentRoute.pathname.includes("/collections") || currentRoute.pathname.includes("/products") || currentRoute.pathname.includes("/account") || currentRoute.pathname.includes("/articles")) {
        return color2
      }
      return color1
    },
    [currentRoute]
  )

  const defaultLinkColor = React.useMemo(() => {
    return defaultOrFlipped(settings?.headerDefaultLinkColor?.hex || "#000000", settings?.headerHoverLinkColor?.hex || "#BE1724")
  }, [settings, defaultOrFlipped])

  useEffect(() => {
    if (currentRoute.pathname !== storedRoute) {
      setStoredRoute(currentRoute.pathname)
      navDispatch({
        type: "TOGGLE_NAV_FLIPPED",
        payload: currentRoute.pathname.includes("/collections") || currentRoute.pathname.includes("/products"),
      })
    }
  }, [currentRoute, storedRoute, navDispatch])

  return {
    defaultLinkColor,
    defaultHoverLinkColor: settings?.headerHoverLinkColor?.hex || "#BE1724",
    defaultBackgroundColor: settings?.headerActiveBackgroundColor?.hex || "#FFFFFF",
    defaultOrFlipped,
  }
}
