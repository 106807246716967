import { useState, useCallback, useMemo } from "react"
import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useShopify } from "./useShopify"
import { useAnalytics } from "./useAnalytics"
import { useEmarsys } from "./useEmarsys"
import { CheckoutProcessor, FREE_GIFT_ATTR } from "../utils/checkoutProcessor"
import { useKlaviyo } from "./useKlaviyo"
import { useCustomerContext } from "./useCustomer"
import { useCartContext } from "../providers/cart"

export const useCart = () => {

  const {
    helpers: { decodeShopifyId, storage },
    graphql: {
      mutations: {
        CART_LINE_ADD, 
        CART_LINE_UPDATE, 
        CART_LINE_REMOVE, 
        CART_DISCOUNT_CODES_UPDATE
      }
    }
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()
  const { customer } = useCustomerContext()
  const { id:cartId, cart, livePromotionRules, saveCart, freeGiftSets, setFreeGiftSets } = useCartContext()

  const { useMutation } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const { trackPage } = useEmarsys()
  const { trackKlaviyoAddToCart } = useKlaviyo();
  const { cartNormaliser } = useShopify()


  const [loading, setLoading] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [errors, setErrors] = useState([])
  const [, dispatch] = globalStateReducer

  const [cartLinesRemove] = useMutation(CART_LINE_REMOVE)
  const [cartLinesUpdate] = useMutation(CART_LINE_UPDATE)
  const [discountCodeUpdate] = useMutation(CART_DISCOUNT_CODES_UPDATE)
  const [cartLinesAdd] = useMutation(CART_LINE_ADD)



  const availableFreeGiftSets = useMemo(() => {
    const addedFreeGiftsTally = {} // promotionRuleKey => count
    const lineItems = cart ? cart.lines : []
    for (const lineItem of lineItems) {
      for (const customAttribute of lineItem.attributes) {
        if (customAttribute.key === FREE_GIFT_ATTR) {
          addedFreeGiftsTally[customAttribute.value] = addedFreeGiftsTally[customAttribute.value] || 0
          addedFreeGiftsTally[customAttribute.value] += lineItem.quantity
        }
      }
    }
    const available = []
    
    if(freeGiftSets) {
      for (const freeGiftSet of freeGiftSets) {
        if (
          typeof addedFreeGiftsTally[freeGiftSet.promotionActionKey] === "undefined" ||
          addedFreeGiftsTally[freeGiftSet.promotionActionKey] < freeGiftSet.takeCount
        ) {
          available.push(freeGiftSet)
        }
      }
    }
    
    return available
  }, [freeGiftSets, cart])

  /**
   * A list of products in availableFreeGiftSets, excluding those that are
   * already included in the cart
   */
  const availableFreeGiftProducts = useMemo(() => {
    let freeGiftProducts = []
    for (const freeGiftSet of availableFreeGiftSets) {
      freeGiftProducts = freeGiftProducts.concat(freeGiftSet.fromProducts)
    }

    return freeGiftProducts
  }, [availableFreeGiftSets])

  const addToCart = useCallback(
    async (product: any, variant: NormalisedVariant, quantity = 1, attributes: Attribute[] = []) => {
      setLoading(true)

      const processor = new CheckoutProcessor(cart, livePromotionRules)
      processor.addLineItem(product, variant, quantity, attributes)
      const { freeGiftSets: newFreeGiftSets } = processor.finaliseLineItemInput()

      // Remove empty attributes if any, this causes an error in the cart
      attributes = attributes?.filter(({ value }) => !!value)

      const lines = {
        attributes:
          attributes?.map(({ key, value }) => ({
            key,
            value,
          })) || [],
        quantity: quantity,
        merchandiseId: variant.id,
      }

      const {
        data: { cartLinesAdd: data },
      } = await cartLinesAdd({
        variables: {
          cartId,
          lines,
        },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.cart) {
        // need to look at the normaliser
        setFreeGiftSets(newFreeGiftSets)
        saveCart(data.cart)
        dispatch({
          type: "SHOW_CART"
        })

        const normalisedCart = cartNormaliser(data.cart)
        trackCartUpdate("add", variant.id, quantity, normalisedCart?.lines)
        trackPage(customer, normalisedCart)
        trackKlaviyoAddToCart(variant.id, quantity, normalisedCart)

 
      }
      setLoading(false)
    },
    [cartLinesAdd, cartId, cartNormaliser, trackCartUpdate, decodeShopifyId, saveCart, dispatch, setLoading]
  )

  const addFreeGiftToCart = useCallback(
    selectedVariant => {
      // Find the corresponding freeGiftSet which the variant belongs to
      let freeGiftSet = null
      let shopifyProduct = null
      loop: {
        for (const set of availableFreeGiftSets) {
          for (const product of set.fromProducts) {
            const parsedShopifyProduct = JSON.parse(product.shopify.shopifyRaw)
            for (const variant of parsedShopifyProduct.variants) {
              if (
                decodeShopifyId(variant.id, "ProductVariant")?.replace("gid://shopify/ProductVariant/", "") ===
                selectedVariant.id?.replace("gid://shopify/ProductVariant/", "")
              ) {
                freeGiftSet = set
                shopifyProduct = parsedShopifyProduct
                break loop
              }
            }
          }
        }
      }
      if (!freeGiftSet || !shopifyProduct) {
        return
      }
      // Add gift to cart
      return addToCart(shopifyProduct, selectedVariant, 1, [{ key: FREE_GIFT_ATTR, value: freeGiftSet.promotionActionKey }])
    },
    [availableFreeGiftSets, addToCart]
  )

  const removeFromCart = useCallback(
    async (id: string, variantId: string) => {
      setLoadingRemove(true)

      const quantity = cart?.lines.filter(line => line.id === id).map(({ quantity }) => quantity)[0] || 1
      const lineIds = cart?.lines.filter(line => line.id === id).map(line => line.id)

      const processor = new CheckoutProcessor(cart, livePromotionRules)
      lineIds?.map((id) => {
        processor.removeLineItem(id)
      })

      const { freeGiftSets: newFreeGiftSets } = processor.finaliseLineItemInput()
  
      // Tracking Event
      const normalisedCart = cartNormaliser(cart)
      trackCartUpdate("remove", variantId, quantity, normalisedCart?.lines || [])


      const {
        data: { cartLinesRemove: data },
      } = await cartLinesRemove({
        variables: { cartId, lineIds },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoadingRemove(false)
      }

      // Tracking Event
      if (data.cart) {
        setFreeGiftSets(newFreeGiftSets)
        saveCart(data?.cart)
        trackPage(customer, normalisedCart)
      }
      setLoadingRemove(false)
    },
    [cart?.lines, trackCartUpdate, cartLinesRemove, cartId, cartNormaliser, saveCart, setLoading]
  )

  const updateQuantity = useCallback(
    async (id: string, variantId: string, quantity: number, action = "change") => {
      setLoading(true)

      const processor = new CheckoutProcessor(cart, livePromotionRules)
      processor.updateLineItemQuantity(id, quantity)
      const { freeGiftSets: newFreeGiftSets } = processor.finaliseLineItemInput()

      const lines = cart?.lines
        .filter(line => line.id === id)
        .map(line => ({
          id: line.id,
          quantity: quantity,
        }))

      const {
        data: { cartLinesUpdate: data },
      } = await cartLinesUpdate({
        variables: { cartId, lines },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }


      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        saveCart(data.cart)
        setFreeGiftSets(newFreeGiftSets)

        trackPage(customer, normalisedCart)
        trackCartUpdate(action, variantId, quantity, normalisedCart?.lines)
      }
      setLoading(false)
    },
    [saveCart, cart, cartLinesUpdate, cartId, cartNormaliser, trackCartUpdate, setLoading]
  )

  const applyDiscountCode = useCallback(
    async (discountCode: string) => {
      setLoading(true)

      // convert to array
      const discountCodes = [discountCode];
      // if adding a code, append all existing codes to array too
      // if removing code and passing empty string, don't ned to do this.
      if (discountCode) {
        cart?.discountCodes?.forEach((code) => {
          discountCodes.push(code?.code)
        })
      }

      const {
        data: { cartDiscountCodesUpdate: data },
      } = await discountCodeUpdate({
        variables: { cartId, discountCodes },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.cart) {
        saveCart(data.cart)
      }
      setLoading(false)
    },
    [saveCart, cart, cartLinesUpdate, cartId, cartNormaliser, trackCartUpdate, setLoading]
  )

  return {
    errors,
    loading,
    loadingRemove,
    availableFreeGiftProducts,
    addToCart,
    updateQuantity,
    removeFromCart,
    addFreeGiftToCart,
    applyDiscountCode,   
  }
}
