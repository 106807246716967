import { cloneDeep } from "lodash-es"
import { encryptNumber } from "./encrypt"

const APPLIED_PROMOTION_RULES_ATTR = "_appliedPromotionRules"
const PROMOTION_TAGS_ATTR = "_promotionTags"
const PROMOTION_PRICE_ATTR = "_promo"
const PROMOTION_EXCLUSIVE = "_promotionalExclusive"
/** List of custom attributes that got automatically added when applying promotional rules */
const PROMOTIONAL_ATTRS = [APPLIED_PROMOTION_RULES_ATTR, PROMOTION_TAGS_ATTR, PROMOTION_PRICE_ATTR]
export const FREE_GIFT_ATTR = "_promotionalFreeGift"

const assertNever = (type: string, a: never): void => {
  console.error(`Unknown ${type}:`, a)
}

const generateRandomString = (length = 8) => {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789"
  let str = ""
  while (str.length < length) {
    str += chars[Math.floor(Math.random() * chars.length)]
  }
  return str
}

const generateRandomId = () => {
  return `${generateRandomString()}_${Date.now()}`
}

/**
 * Helper class to capture the logic of adding or removing line items onto the
 * Shopify cart. Part of the logic encapsulated is applying promotion rules
 * currently active.
 */
export class CheckoutProcessor {
  private checkout
  private livePromotionRules: PromotionRule[]
  private checkoutLineItems: CheckoutLineItem[] = []
  private promotionExclusiveLineItems: CheckoutLineItem[] = []
  private freeGiftSets: FreeGiftSet[]

  constructor(checkout = null, livePromotionRules = []) {
    this.checkout = checkout
    this.livePromotionRules = livePromotionRules
    this.freeGiftSets = []
    this.populateCheckoutLineItems()
  }

  private populateCheckoutLineItems(): void {
    const normalisedLineItems: CheckoutLineItem[] = this.normaliseShopifyLineItems(this.checkout.lineItems)
    this.checkoutLineItems = normalisedLineItems
    this.splitPromotionExclusiveLineItems()
  }

  /**
   * Normalises Shopify line item entries into CheckoutLineItem which is
   * suitable for calculating custom promotions. The normalisation process will
   * also do the following:
   * - Remove custom promotion line item attributes
   * - Remove custom promotion discount applications
   */
  private normaliseShopifyLineItems(shopifyLineItems): CheckoutLineItem[] {
    const normalisedLineItems = []
    if (shopifyLineItems) {
      for (const shopifyLineItem of shopifyLineItems) {
        const normalisedShopifyDiscounts: ShopifyDiscount[] = []
        for (const shopifyDiscount of shopifyLineItem.discountAllocations) {
          if (shopifyDiscount?.discountApplication?.__typename === "ScriptDiscountApplication" || !shopifyDiscount?.discountApplication?.__typename) {
            continue
          }
          normalisedShopifyDiscounts.push({
            allocatedAmount: Number(shopifyDiscount.allocatedAmount.amount),
            discountApplication: {
              type: shopifyDiscount.discountApplication.__typename,
              value: {
                type: shopifyDiscount.discountApplication.value.__typename === "MoneyV2" ? "Money" : "Percentage",
                amount:
                  shopifyDiscount.discountApplication.value.__typename === "MoneyV2"
                    ? Number(shopifyDiscount.discountApplication.value.amount)
                    : shopifyDiscount.discountApplication.value.percentage,
              },
              description: shopifyDiscount.discountApplication.code || shopifyDiscount.discountApplication.title || "",
            },
          })
        }
  
        const filteredCustomAttributes = shopifyLineItem.customAttributes.filter(attr => {
          return PROMOTIONAL_ATTRS.indexOf(attr.key) < 0
        })
        normalisedLineItems.push({
          id: shopifyLineItem.id,
          quantity: shopifyLineItem.quantity,
          variant: {
            id: shopifyLineItem.variant.id,
            unitPrice: Number(shopifyLineItem.variant.priceV2.amount),
            product: {
              id: shopifyLineItem.variant.product.id,
              tags: shopifyLineItem.variant.product.tags,
              sku: shopifyLineItem.variant.sku,
              handle: shopifyLineItem.variant.product.handle,
            },
          },
          customAttributes: filteredCustomAttributes,
          shopifyDiscounts: normalisedShopifyDiscounts,
          totalCustomPromotionDiscount: 0,
        })
      }
    }
  
    // console.log('normalisedLineItems',normalisedLineItems)
    return normalisedLineItems
  }

  /**
   * Merge normalised line items that have been previously split by custom
   * promotions calculations. This is only to be called before making any custom
   * promotion calculations.
   */
  private mergeCheckoutLineItems(checkoutLineItems: CheckoutLineItem[]): CheckoutLineItem[] {
    let index = 0
    while (index < checkoutLineItems.length) {
      const currentLineItem = checkoutLineItems[index]
      const currentVariantId = currentLineItem.variant.id
      const currentShopifyDiscountDescriptions = currentLineItem.shopifyDiscounts.map(d => d.discountApplication.description).sort()
      const currentCustomAttributes = currentLineItem.customAttributes.sort((a, b) => {
        if (a.key < b.key) {
          return -1
        } else if (a.key > b.key) {
          return 1
        } else {
          return 0
        }
      })
      let mergeIndex = -1
      for (let i = 0; i < index; i += 1) {
        const lineItem = checkoutLineItems[i]
        const variantId = lineItem.variant.id
        if (variantId === currentVariantId) {
          // Check custom attributes and Shopify discounts
          const shopifyDiscountDescriptions = lineItem.shopifyDiscounts.map(d => d.discountApplication.description).sort()
          let shopifyDiscountsMatch = shopifyDiscountDescriptions.length === currentShopifyDiscountDescriptions.length
          if (shopifyDiscountsMatch) {
            for (let j = 0; j < shopifyDiscountDescriptions.length; j += 1) {
              if (shopifyDiscountDescriptions[j] !== currentShopifyDiscountDescriptions[j]) {
                shopifyDiscountsMatch = false
                break
              }
            }
          }

          const customAttributes = lineItem.customAttributes.sort((a, b) => {
            if (a.key < b.key) {
              return -1
            } else if (a.key > b.key) {
              return 1
            } else {
              return 0
            }
          })
          let customAttributesMatch = customAttributes.length === currentCustomAttributes.length
          if (customAttributesMatch) {
            for (let j = 0; j < customAttributes.length; j += 1) {
              if (customAttributes[j].key !== currentCustomAttributes[j].key || customAttributes[j].value !== currentCustomAttributes[j].value) {
                customAttributesMatch = false
                break
              }
            }
          }

          if (shopifyDiscountsMatch && customAttributesMatch) {
            mergeIndex = i
            break
          }
        }
      }

      if (mergeIndex >= 0) {
        checkoutLineItems[mergeIndex].quantity += currentLineItem.quantity
        checkoutLineItems[mergeIndex].totalCustomPromotionDiscount += currentLineItem.totalCustomPromotionDiscount
        for (const shopifyDiscount of checkoutLineItems[mergeIndex].shopifyDiscounts) {
          const currentShopifyDiscount = currentLineItem.shopifyDiscounts.find(
            d => d.discountApplication.description === shopifyDiscount.discountApplication.description
          )
          shopifyDiscount.allocatedAmount += currentShopifyDiscount?.allocatedAmount || 0
        }
        checkoutLineItems.splice(index, 1)
      } else {
        index += 1
      }
    }

    return checkoutLineItems
  }

  /**
   * Separate out line items that are free gift or marked as excluded from
   * promotions.
   */
  private splitPromotionExclusiveLineItems(): void {
    const allLineItems = this.checkoutLineItems.concat(this.promotionExclusiveLineItems)
    const promotionInclusives = allLineItems.filter(lineItem =>
      lineItem.customAttributes.every(attribute => attribute.key !== PROMOTION_EXCLUSIVE && attribute.key !== FREE_GIFT_ATTR)
    )
    const promotionExclusives = allLineItems.filter(lineItem =>
      lineItem.customAttributes.some(attribute => attribute.key === PROMOTION_EXCLUSIVE || attribute.key === FREE_GIFT_ATTR)
    )
    this.checkoutLineItems = promotionInclusives
    this.promotionExclusiveLineItems = promotionExclusives
  }

  private applyPromotionsToLineItems(): void {
    const rulesToUse = this.livePromotionRules
    // const rulesToUse = [this.livePromotionRules[this.livePromotionRules.length - 1]]

    for (const promotionRule of rulesToUse) {
      const promotionIsApplied = this.executePromotionRule(promotionRule)
      if (promotionIsApplied && promotionRule.isTheLast) {
        break
      }
    }
  }

  private executePromotionRule(promotionRule: PromotionRule) {
    let ruleIsApplied = false
    const { triggered, triggeringLineItemGroups } = this.evaluateTrigger(promotionRule)
    if (triggered) {
      this.executeAction(promotionRule, triggeringLineItemGroups)
      ruleIsApplied = true
    }
    return ruleIsApplied
  }

  /**
   * Evaluates the list of triggers in a promotion rule, and will stop as soon
   * as a trigger is evaluating to true
   */
  private evaluateTrigger(promotionRule: PromotionRule): TriggerEvaluationResult {
    const evalResult: TriggerEvaluationResult = {
      triggered: false,
      triggeringLineItemGroups: [],
    }

    const exclusionTags = promotionRule.exclusions || []
    const triggers = promotionRule.rule.trigger
    for (const trigger of triggers) {
      if (evalResult.triggered) {
        break
      }
      // Only process line items that don't have tags in common with promotion rule
      const lineItemsToProcess = this.checkoutLineItems.filter(lineItem => {
        const lineItemPromotionTags = lineItem.customAttributes.find(attr => attr.key === PROMOTION_TAGS_ATTR)?.value.split(",") || []
        return lineItemPromotionTags.length === 0 || lineItemPromotionTags.every(tag => promotionRule.promotionTags.indexOf(tag) < 0)
      })

      if (typeof trigger._type === "undefined") {
        // Process no-condition trigger
        evalResult.triggered = true
        evalResult.triggeringLineItemGroups = [
          lineItemsToProcess.map(lineItem => ({
            lineItem,
            quantity: lineItem.quantity,
            contributingAmount: this.getLineItemTotal(lineItem, true),
          })),
        ]
        continue
      }

      const cartTotal = this.getCartTotal(exclusionTags)
      switch (trigger._type) {
        case "promotionTriggerCartTotalValue":
          if (typeof trigger.valueMin !== "undefined" && typeof trigger.valueMax !== "undefined") {
            evalResult.triggered = cartTotal >= trigger.valueMin && cartTotal <= trigger.valueMax
          } else if (typeof trigger.valueMin !== "undefined" && typeof trigger.valueMax === "undefined") {
            evalResult.triggered = cartTotal >= trigger.valueMin
          } else if (typeof trigger.valueMin === "undefined" && typeof trigger.valueMax !== "undefined") {
            evalResult.triggered = cartTotal <= trigger.valueMax
          }
          if (evalResult.triggered) {
            evalResult.triggeringLineItemGroups = [
              lineItemsToProcess.map(lineItem => ({
                lineItem,
                quantity: lineItem.quantity,
                contributingAmount: this.getLineItemTotal(lineItem, true),
              })),
            ]
          }
          break
        case "promotionTriggerSpecificQuantityProduct": {
          let matchingLineItems: TriggeringLineItems = []
          for (const lineItem of lineItemsToProcess) {
            const matched = lineItem.variant.product.tags.indexOf(trigger.tag) >= 0 && !this.isLineItemExcluded(lineItem, exclusionTags)

            if (matched) {
              matchingLineItems = matchingLineItems.concat({
                lineItem,
                quantity: lineItem.quantity,
                contributingAmount: this.getLineItemTotal(lineItem, true),
              })
            }
          }

          while (matchingLineItems.length > 0) {
            let matchingCount = 0
            const groupedLineItems: TriggeringLineItems = []
            while (matchingLineItems.length > 0) {
              const line = matchingLineItems.shift()
              if (matchingCount + line.quantity >= trigger.quantity) {
                const splitQuantity = trigger.quantity - matchingCount
                const splitContributingAmount = this.getLineItemPartial(line.lineItem, splitQuantity, true)

                const splitLine = {
                  lineItem: line.lineItem,
                  quantity: splitQuantity,
                  contributingAmount: splitContributingAmount,
                }

                groupedLineItems.push(splitLine)
                evalResult.triggered = true
                evalResult.triggeringLineItemGroups.push(groupedLineItems)

                line.quantity = line.quantity - splitLine.quantity
                line.contributingAmount = line.contributingAmount - splitLine.contributingAmount
                if (line.quantity > 0) {
                  matchingLineItems.unshift(line)
                }

                break
              } else {
                matchingCount += line.quantity
                groupedLineItems.push(line)
              }
            }
          }

          break
        }
        case "promotionTriggerCartTagTotalValue": {
          let matchingLineItems: TriggeringLineItems = []
          let totalTagValue = 0
          for (const lineItem of lineItemsToProcess) {
            const matched = lineItem.variant.product.tags.includes(trigger?.tag?.trim()) && !this.isLineItemExcluded(lineItem, exclusionTags)
            if (matched) {
              totalTagValue += this.getLineItemTotal(lineItem, true)
              matchingLineItems = matchingLineItems.concat({
                lineItem,
                quantity: lineItem.quantity,
                contributingAmount: this.getLineItemTotal(lineItem, true),
              })
            }
          }
          if (totalTagValue >= trigger?.valueMin) {
            evalResult.triggered = true
            evalResult.triggeringLineItemGroups.push(matchingLineItems)
          }
          break
        }
        case "promotionTriggerSpecificQuantityProductByProduct": {
          let matchingLineItems: TriggeringLineItems = []

          const bundleProducts = trigger.productList?.map(p => ({
            ...p,
            matched: false,
            matchedQty: 0,
            processedQty: 0,
            handles: p?.tags?.reduce((arr, o) => {
              const split = o?.split(":")
              if (split?.[0] === "colours") {
                arr.push(split?.[1])
              }
              return arr
            }, []),
          }))

          for (const lineItem of lineItemsToProcess) {
            const bundleProduct = bundleProducts?.find(p => {
              return p?.handles?.includes(lineItem?.variant?.product?.handle) || p?.shopify?.shopifyHandle === lineItem?.variant?.product?.handle
            })

            if (bundleProduct) {
              bundleProduct.matched = true
              bundleProduct.matchedQty += lineItem.quantity
              matchingLineItems = matchingLineItems.concat({
                lineItem,
                quantity: lineItem.quantity,
                contributingAmount: this.getLineItemTotal(lineItem, true),
              })
            }
          }

          const numberOfBundles = bundleProducts?.reduce((min, p) => (min === 0 ? p?.matchedQty : Math.min(p?.matchedQty, min)), 0)
          if (numberOfBundles === 0 || bundleProducts?.filter(p => p?.matched)?.length !== trigger.productList.length) {
            break
          }

          const groupedLineItems: TriggeringLineItems = []
          for (const lineItem of matchingLineItems) {
            const bundleProduct = bundleProducts?.find(p => {
              return (
                p?.handles?.includes(lineItem.lineItem.variant.product.handle) ||
                p?.shopify?.shopifyHandle === lineItem?.lineItem?.variant?.product?.handle
              )
            })

            if (bundleProduct?.processedQty < numberOfBundles) {
              const qtyInBundle = Math.min(lineItem.quantity, numberOfBundles)
              bundleProduct.processedQty += qtyInBundle

              const splitContributingAmount = this.getLineItemPartial(lineItem.lineItem, qtyInBundle, true)

              const splitLine = {
                lineItem: lineItem.lineItem,
                quantity: qtyInBundle,
                contributingAmount: splitContributingAmount,
              }

              groupedLineItems.push(splitLine)

              lineItem.quantity = lineItem.quantity - splitLine.quantity
              lineItem.contributingAmount = lineItem.contributingAmount - splitLine.contributingAmount
            }
          }

          evalResult.triggered = true
          evalResult.triggeringLineItemGroups.push(groupedLineItems)
          break
        }
        default:
          assertNever("promotion trigger", trigger)
      }
    }

    return evalResult
  }

  /**
   * Modifies checkoutLineItems as a result of applying the action of a
   * promotion rule
   */
  private executeAction(promotionRule: PromotionRule, triggeringLineItemGroups: TriggeringLineItems[]): void {
    for (const action of promotionRule.rule.action) {
      switch (action._type) {
        case "promotionActionAllTriggeredProductsDiscount":
          for (const triggeringLineItems of triggeringLineItemGroups) {
            const totalQuantity = triggeringLineItems.reduce((totalQty, lineItem) => {
              return totalQty + lineItem.quantity
            }, 0)
            const totalContributingValue = triggeringLineItems.reduce((total, triggerLineItem) => {
              return total + triggerLineItem.contributingAmount
            }, 0)
            let appliedDiscount = 0
            let totalValueBeforeDiscount = 0

            for (let i = 0; i < triggeringLineItems.length; i += 1) {
              const triggeringLineItem = triggeringLineItems[i]
              const targetLineItemIndex = this.checkoutLineItems.findIndex(l => l.id === triggeringLineItem.lineItem.id)
              if (targetLineItemIndex < 0) {
                continue
              }
              let targetLineItem = this.checkoutLineItems[targetLineItemIndex]

              if (targetLineItem.quantity > triggeringLineItem.quantity) {
                // Split line item
                const newLineItem: CheckoutLineItem = cloneDeep(targetLineItem)
                newLineItem.id = generateRandomId()
                newLineItem.quantity = triggeringLineItem.quantity
                targetLineItem.quantity = targetLineItem.quantity - newLineItem.quantity
                this.checkoutLineItems.splice(targetLineItemIndex, 1, newLineItem, targetLineItem)
                targetLineItem = newLineItem
              }

              let discountAmount = 0

              switch (action.type) {
                case "amountOff": {
                  discountAmount = (action.value * triggeringLineItem.quantity) / totalQuantity
                  if (i === triggeringLineItems.length - 1) {
                    discountAmount = action.value - appliedDiscount // This makes sure no rounding errors
                  }
                  discountAmount = Math.round(discountAmount * 100) / 100 // Round to nearest cents
                  appliedDiscount += discountAmount
                  targetLineItem.totalCustomPromotionDiscount += discountAmount
                  break
                }
                case "amountTo": {
                  const isBundleTrigger = promotionRule?.rule?.trigger?.[0]?._type === "promotionTriggerSpecificQuantityProductByProduct"
                  const totalDiscount =
                    totalContributingValue -
                    action.value * (isBundleTrigger ? totalQuantity / promotionRule?.rule?.trigger?.[0]?.productList?.length : 1)
                  discountAmount =
                    (totalDiscount * this.getLineItemPartial(targetLineItem, triggeringLineItem.quantity, true)) / totalContributingValue

                  if (i === triggeringLineItems.length - 1) {
                    discountAmount = totalDiscount - appliedDiscount // This makes sure no rounding errors
                  }
                  discountAmount = Math.round(discountAmount * 100) / 100 // Round to nearest cents
                  appliedDiscount += discountAmount
                  targetLineItem.totalCustomPromotionDiscount += discountAmount
                  break
                }
                case "percent": {
                  const currentLineItemTotal = this.getLineItemTotal(targetLineItem, true)
                  totalValueBeforeDiscount += currentLineItemTotal
                  if (i === triggeringLineItems.length - 1) {
                    const totalTargetDiscountAmount = totalValueBeforeDiscount * (action.value / 100)
                    discountAmount = totalTargetDiscountAmount - appliedDiscount // This makes sure no rounding errors
                  } else {
                    const newDiscountedTotal = currentLineItemTotal * (1 - action.value / 100)
                    discountAmount = currentLineItemTotal - newDiscountedTotal
                  }
                  discountAmount = Math.round(discountAmount * 100) / 100 // Round to nearest cents
                  appliedDiscount += discountAmount
                  targetLineItem.totalCustomPromotionDiscount += discountAmount
                  break
                }
                default:
                  assertNever("action type", action)
              }
              this.labelLineItemWithPromotionRule(targetLineItem, promotionRule, discountAmount)
            }
          }
          break
        case "promotionActionCheapestTriggeredProductsDiscount":
          for (const triggeringLineItems of triggeringLineItemGroups) {
            let targetLineItem: CheckoutLineItem = null
            let cheapestUnitPrice = 0
            for (const triggeringLineItem of triggeringLineItems) {
              if (targetLineItem === null || triggeringLineItem.lineItem.variant.unitPrice < cheapestUnitPrice) {
                targetLineItem = triggeringLineItem.lineItem
                cheapestUnitPrice = targetLineItem.variant.unitPrice
              }
            }
            const targetLineItemIndex = this.checkoutLineItems.findIndex(l => l.id === targetLineItem.id)
            if (targetLineItemIndex < 0) {
              continue
            }
            targetLineItem = this.checkoutLineItems[targetLineItemIndex]
            if (targetLineItem.quantity > 1) {
              // Split line item
              const newLineItem: CheckoutLineItem = cloneDeep(targetLineItem)
              newLineItem.id = generateRandomId()
              newLineItem.quantity = 1
              targetLineItem.quantity = targetLineItem.quantity - newLineItem.quantity
              this.checkoutLineItems.splice(targetLineItemIndex, 1, newLineItem, targetLineItem)
              targetLineItem = newLineItem
            }

            let discountAmount = 0

            switch (action.type) {
              case "amountOff":
                discountAmount = action.value
                targetLineItem.totalCustomPromotionDiscount += discountAmount
                break
              case "amountTo": {
                const currentLineItemTotal = this.getLineItemTotal(targetLineItem, true)
                discountAmount = currentLineItemTotal - action.value
                targetLineItem.totalCustomPromotionDiscount += discountAmount
                break
              }
              case "percent": {
                const currentLineItemTotal = this.getLineItemTotal(targetLineItem, true)
                const newDiscountedTotal = currentLineItemTotal * (1 - action.value / 100)
                discountAmount = currentLineItemTotal - newDiscountedTotal
                discountAmount = Math.round(discountAmount * 100) / 100 // Round to nearest cents
                targetLineItem.totalCustomPromotionDiscount += discountAmount
                break
              }
              default:
                assertNever("action type", action)
            }
            this.labelLineItemWithPromotionRule(targetLineItem, promotionRule, discountAmount)
          }
          break
        case "promotionActionEntireCartDiscount": {
          const totalQuantity = this.checkoutLineItems
            .filter(item => !this.isLineItemExcluded(item, promotionRule.exclusions))
            .reduce((totalQty, lineItem) => {
              return totalQty + lineItem.quantity
            }, 0)
          let totalValueBeforeDiscount = 0
          let appliedDiscount = 0
          for (let i = 0; i < this.checkoutLineItems.length; i += 1) {
            const targetLineItem = this.checkoutLineItems[i]
            let discountAmount = 0

            switch (action.type) {
              case "amountOff": {
                if (!this.isLineItemExcluded(targetLineItem, promotionRule.exclusions)) {
                  discountAmount = (action.value * targetLineItem.quantity) / totalQuantity
                  if (i === this.checkoutLineItems.length - 1) {
                    discountAmount = action.value - appliedDiscount // This makes sure no rounding errors
                  }
                  discountAmount = Math.round(discountAmount * 100) / 100 // Round to nearest cents
                  appliedDiscount += discountAmount
                  targetLineItem.totalCustomPromotionDiscount += discountAmount
                }
                break
              }
              case "percent": {
                const currentLineItemTotal = this.getLineItemTotal(targetLineItem, true)

                if (!this.isLineItemExcluded(targetLineItem, promotionRule.exclusions)) {
                  totalValueBeforeDiscount += currentLineItemTotal
                  if (i === this.checkoutLineItems.length - 1) {
                    const totalTargetDiscountAmount = totalValueBeforeDiscount * (action.value / 100)
                    discountAmount = totalTargetDiscountAmount - appliedDiscount // This makes sure no rounding errors
                  } else {
                    const newDiscountedTotal = currentLineItemTotal * (1 - action.value / 100)
                    discountAmount = currentLineItemTotal - newDiscountedTotal
                  }
                  discountAmount = Math.round(discountAmount * 100) / 100 // Round to nearest cents
                  appliedDiscount += discountAmount
                  targetLineItem.totalCustomPromotionDiscount += discountAmount
                }
                break
              }
              default:
                assertNever("action type", action)
            }
            this.labelLineItemWithPromotionRule(targetLineItem, promotionRule, discountAmount)
          }
          break
        }
        case "promotionActionFreeGift":
          this.freeGiftSets.push({
            promotionActionKey: action._key,
            takeCount: 1,
            fromProducts: action.products,
          })

          // Label all triggering line items
          for (const triggeringLineItems of triggeringLineItemGroups) {
            for (const triggeringLineItem of triggeringLineItems) {
              const targetLineItem = this.checkoutLineItems.find(l => l.id === triggeringLineItem.lineItem.id)
              this.labelLineItemWithPromotionRule(targetLineItem, promotionRule)
            }
          }
          break
        case "promotionActionFreeGifts":
          this.freeGiftSets.push({
            promotionActionKey: action._key,
            takeCount: action.products.length,
            fromProducts: action.products,
          })

          // Label all triggering line items
          for (const triggeringLineItems of triggeringLineItemGroups) {
            for (const triggeringLineItem of triggeringLineItems) {
              const targetLineItem = this.checkoutLineItems.find(l => l.id === triggeringLineItem.lineItem.id)
              this.labelLineItemWithPromotionRule(targetLineItem, promotionRule)
            }
          }
          break
        case "promotionActionFreeGiftAutomatic": {
          // addLineItem(
          //   product,
          //   variant,
          //   quantity: number,
          //   customAttributes: CustomAttribute[],
          // ): void {
          //   const newLineItem: CheckoutLineItem = {
          //     id: generateRandomId(),
          //     customAttributes,
          //     quantity,
          //     shopifyDiscounts: [],
          //     totalCustomPromotionDiscount: 0,
          //     variant: {
          //       id: variant.id,
          //       unitPrice: Number(variant.priceV2.amount),
          //       product: {
          //         id: product.id,
          //         tags: product.tags,
          //       },
          //     },
          //   }
          //   this.checkoutLineItems.push(newLineItem)
          //   this.splitPromotionExclusiveLineItems()
          // }

          const product = action?.products?.[0]

          if (product) {
            const variant = JSON.parse(product?.shopify?.shopifyRaw)
            console.log(product?.shopify?.shopifyHandle)
            console.log(variant)
          }
          // this.addLineItem(product, variant, 1, [])
          // this.freeGiftSets.push({
          //   promotionActionKey: action._key,
          //   takeCount: 1,
          //   fromProducts: action.products,
          // })

          // // Label all triggering line items
          // for (const triggeringLineItems of triggeringLineItemGroups) {
          //   for (const triggeringLineItem of triggeringLineItems) {
          //     const targetLineItem = this.checkoutLineItems.find(
          //       l => l.id === triggeringLineItem.lineItem.id,
          //     )
          //     this.labelLineItemWithPromotionRule(
          //       targetLineItem,
          //       promotionRule,
          //     )
          //   }
          // }
          break
        }
        default:
          assertNever("promotion action", action)
      }
    }
  }

  private labelLineItemWithPromotionRule(
    lineItem: CheckoutLineItem,
    promotionRule: PromotionRule,
    // eslint-disable-next-line
    discountAmount = 0
  ): void {
    const appliedPromotion = promotionRule.rule.title
    if (this.isLineItemExcluded(lineItem, promotionRule.exclusions)) return
    // const appliedPromotion = discountAmount
    // ? `${promotionRule.rule.title} ($${discountAmount})`
    // : promotionRule.rule.title

    // Update applied promotions attribute
    const attributeIndex = lineItem.customAttributes.findIndex(attr => attr.key === APPLIED_PROMOTION_RULES_ATTR)
    if (attributeIndex < 0) {
      lineItem.customAttributes.push({
        key: APPLIED_PROMOTION_RULES_ATTR,
        value: appliedPromotion,
      })
    } else {
      const appliedRules = lineItem.customAttributes[attributeIndex].value.split(",").reduce((hash, rule) => {
        hash[rule] = true
        return hash
      }, {})
      appliedRules[appliedPromotion] = true
      lineItem.customAttributes[attributeIndex].value = Object.keys(appliedRules).join(",")
    }

    // Update promotion tags attribute
    const promotionTagsAttrIndex = lineItem.customAttributes.findIndex(attr => attr.key === PROMOTION_TAGS_ATTR)
    if (promotionTagsAttrIndex < 0) {
      lineItem.customAttributes.push({
        key: PROMOTION_TAGS_ATTR,
        value: promotionRule.promotionTags.join(","),
      })
    } else {
      const appliedTags = lineItem.customAttributes[promotionTagsAttrIndex].value.split(",").reduce((hash, rule) => {
        hash[rule] = true
        return hash
      }, {})
      for (const tag of promotionRule.promotionTags) {
        appliedTags[tag] = true
      }
      lineItem.customAttributes[promotionTagsAttrIndex].value = Object.keys(appliedTags).join(",")
    }
  }

  private isLineItemExcluded(lineItem: CheckoutLineItem, exclusionTags: string[] = []) {
    return lineItem.variant.product.tags.some(tag => exclusionTags.map(t => t.replace(/ /g, "")).includes(tag.replace(/ /g, "")))
  }
  /**
   * Calculates cart total after all discounts on all line items
   */
  private getCartTotal(exclusionTags: string[]): number {
    let total = 0
    for (const lineItem of this.checkoutLineItems) {
      if (!this.isLineItemExcluded(lineItem, exclusionTags)) {
        total += this.getLineItemTotal(lineItem)
      }
    }
    return total
  }

  /**
   * Calculates the final price of the line item after discounts
   */
  private getLineItemTotal(lineItem: CheckoutLineItem, excludeShopifyDiscounts = false): number {
    let total = lineItem.quantity * lineItem.variant.unitPrice
    total -= lineItem.totalCustomPromotionDiscount
    if (!excludeShopifyDiscounts) {
      for (const shopifyDiscount of lineItem.shopifyDiscounts) {
        switch (shopifyDiscount.discountApplication.value.type) {
          case "Money":
            total -= shopifyDiscount.allocatedAmount
            break
          case "Percentage":
            total *= 1 - shopifyDiscount.discountApplication.value.amount / 100
            break
          default:
            assertNever("Shopify discount application type", shopifyDiscount.discountApplication.value.type)
        }
      }
    }
    return total
  }

  /**
   * Calculates the line item's price with partial quantity after discounts
   */
  private getLineItemPartial(lineItem: CheckoutLineItem, quantity: number, excludeShopifyDiscounts = false): number {
    quantity = Math.min(quantity, lineItem.quantity)
    const lineItemTotal = this.getLineItemTotal(lineItem, excludeShopifyDiscounts)
    return (lineItemTotal * quantity) / lineItem.quantity
  }

  addLineItem(product, variant, quantity: number, customAttributes: CustomAttribute[]): void {
    const newLineItem: CheckoutLineItem = {
      id: generateRandomId(),
      customAttributes,
      quantity,
      shopifyDiscounts: [],
      totalCustomPromotionDiscount: 0,
      variant: {
        id: variant.id,
        unitPrice: Number(variant.priceV2.amount),
        product: {
          id: product.id,
          tags: product.tags,
          handle: product.handle,
        },
      },
    }
    this.checkoutLineItems.push(newLineItem)
    this.splitPromotionExclusiveLineItems()
  }

  removeLineItem(lineItemId: string): void {
    const allLineItems = this.checkoutLineItems.concat(this.promotionExclusiveLineItems)
    this.checkoutLineItems = allLineItems.filter(lineItem => {
      return lineItem.id !== lineItemId
    })
    this.promotionExclusiveLineItems = []
    this.splitPromotionExclusiveLineItems()
  }

  updateLineItemQuantity(lineItemId: string, newQuantity: number): void {
    for (const lineItem of this.checkoutLineItems) {
      if (lineItem.id === lineItemId) {
        lineItem.quantity = newQuantity
      }
    }
    this.splitPromotionExclusiveLineItems()
  }

  /**
   * Consolidates all line item changes and run promotions on the resulting list
   * of line items. This method will return line item input for the
   * checkoutLineItemsReplace mutation as well as a list of promotional gift
   * products that the customer is eligible to choose for.
   *
   * IMPORTANT: This method is not idempotent, so it should only be called once
   * after all modifications to the line items have been registered using the
   * other exported methods.
   */
  finaliseLineItemInput(): {
    lineItemInput: LineItemInput
    freeGiftSets: FreeGiftSet[]
  } {
    this.splitPromotionExclusiveLineItems()
    this.applyPromotionsToLineItems()
    this.checkoutLineItems = this.mergeCheckoutLineItems(this.checkoutLineItems)

    const exclusiveLineItemsPayload = this.promotionExclusiveLineItems.map(lineItem => ({
      variantId: lineItem.variant.id,
      quantity: lineItem.quantity,
      customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
        key,
        value,
      })),
    }))

    const inputPayload: LineItemInput = this.checkoutLineItems
      .map(lineItem => {
        const customAttributes = [...lineItem.customAttributes]?.map(({ key, value }) => ({
          key,
          value,
        }))
        if (lineItem.totalCustomPromotionDiscount > 0) {
          const finalDiscountedPriceInCents = Math.max(
            Math.round((lineItem.quantity * lineItem.variant.unitPrice - lineItem.totalCustomPromotionDiscount) * 100),
            0
          )
          customAttributes.push({
            key: PROMOTION_PRICE_ATTR,
            value: encryptNumber(finalDiscountedPriceInCents),
          })
        }
        return {
          variantId: lineItem.variant.id,
          quantity: lineItem.quantity,
          customAttributes,
        }
      })
      .concat(exclusiveLineItemsPayload)

    // Filter out free gift line items that are no longer eligible and ensure
    // free gifts are priced 0
    let index = 0
    const freeGiftCounts = this.freeGiftSets.reduce((count, set) => {
      count[set.promotionActionKey] = count[set.promotionActionKey] || 0
      count[set.promotionActionKey] += set.takeCount
      return count
    }, {})
    while (index < inputPayload.length) {
      const lineItem = inputPayload[index]
      const freeGiftAttr = lineItem.customAttributes.find(attr => attr.key === FREE_GIFT_ATTR)
      if (freeGiftAttr) {
        if (typeof freeGiftCounts[freeGiftAttr.value] === "undefined" || freeGiftCounts[freeGiftAttr.value] <= 0) {
          // Remove line item input
          inputPayload.splice(index, 1)
          continue
        } else {
          // Make sure free gift quantity is within allowed amount
          lineItem.quantity = Math.min(lineItem.quantity, freeGiftCounts[freeGiftAttr.value])
          freeGiftCounts[freeGiftAttr.value] = freeGiftCounts[freeGiftAttr.value] - lineItem.quantity

          // Set free gift price to 0
          const promoPriceAttrIndex = lineItem.customAttributes.findIndex(attr => attr.key === PROMOTION_PRICE_ATTR)
          if (promoPriceAttrIndex < 0) {
            lineItem.customAttributes.push({
              key: PROMOTION_PRICE_ATTR,
              value: encryptNumber(0),
            })
          } else {
            lineItem.customAttributes[promoPriceAttrIndex].value = encryptNumber(0)
          }
        }
      }
      index += 1
    }

    return {
      lineItemInput: inputPayload,
      freeGiftSets: this.freeGiftSets,
    }
  }
}

/**
 * A modified version of Shopify's checkout line item object to assist in
 * calculating custom promotions. All price figures in this object will be in
 * shop's currency.
 */
type CheckoutLineItem = {
  id: string
  quantity: number
  variant: {
    id: string
    unitPrice: number // Original price per unit before any discount
    product: {
      id: string
      tags: string[]
      sku?: string
      handle: string
    }
  }
  customAttributes: CustomAttribute[]
  // Discount originating NOT from custom promotions feature
  // This is purely for internal calculation purposes to calculate the final
  // product price on the cart more accurately. As in Shopify. the allocated
  // amount is the total across all quantities of the line item.
  shopifyDiscounts: ShopifyDiscount[]
  // Total discount from custom promotions feature
  // As with shoifyDiscounts, the total promotion discount is across all
  // quantities of the line item.
  totalCustomPromotionDiscount: number
}

type CustomAttribute = {
  key: string
  value: string
}

type ShopifyDiscount = {
  allocatedAmount: number
  discountApplication: {
    type: "AutomaticDiscountApplication" | "DiscountCodeApplication" | "ManualDiscountApplication" | "ScriptDiscountApplication"
    value: {
      type: "Money" | "Percentage"
      amount: number
    }
    description: string
  }
}

type LineItemInput = {
  customAttributes: CustomAttribute[]
  quantity: number
  variantId: string
}[]

/**
 * A minimal representation of Sanity product attached to PromotionRule. The
 * actual object may contain more fields.
 */
type SanityProduct = {
  id: string
  shopify: {
    shopifyId: string
    shopifyHandle: string
    shopifyRaw: string
  }
}

/**
 * A structure to express: "Take {n} free gifts from {list of products}"
 */
type FreeGiftSet = {
  promotionActionKey: string
  takeCount: number
  fromProducts: SanityProduct[]
}

type PromotionRule = {
  isTheLast: boolean
  promotionTags: string[]
  rule: {
    id: string
    title: string
    trigger: PromotionTrigger[]
    action: PromotionAction[]
  }
  exclusions: string[]
}

type PromotionTrigger =
  | {
      _type: "promotionTriggerSpecificQuantityProduct"
      quantity: number
      tag: string
    }
  | {
      _type: "promotionTriggerCartTotalValue"
      valueMin?: number
      valueMax?: number
    }
  | {
      _type: "promotionTriggerCartTagTotalValue"
      tag?: string
      valueMin?: number
    }
  | {
      _type: "promotionTriggerSpecificQuantityProductByProduct"
      productList?: any
      quantity: number
    }

type PromotionAction = { _key: any } & (
  | {
      _type: "promotionActionAllTriggeredProductsDiscount"
      type: "amountOff" | "amountTo" | "percent" | "totalPrice"
      value: number
    }
  | {
      _type: "promotionActionCheapestTriggeredProductsDiscount"
      type: "amountOff" | "amountTo" | "percent"
      value: number
    }
  | {
      _type: "promotionActionEntireCartDiscount"
      type: "amountOff" | "percent"
      value: number
    }
  | {
      _type: "promotionActionFreeGiftAutomatic"
      products: SanityProduct[]
    }
  | {
      _type: "promotionActionFreeGift"
      products: SanityProduct[]
    }
  | {
      _type: "promotionActionFreeGifts"
      products: SanityProduct[]
    }
)

type TriggerEvaluationResult = {
  triggered: boolean
  /** Array of TriggeringLineItems, when the trigger is satisfied multiple times */
  triggeringLineItemGroups: TriggeringLineItems[]
}

/**
 * List of line items that satisfies a trigger
 */
type TriggeringLineItems = {
  lineItem: CheckoutLineItem
  quantity: number
  contributingAmount: number
}[]
