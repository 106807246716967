import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCartContext } from "../../../providers/cart"
import { useCart } from "../../../hooks/useCart"

export const withCartDrawer = Component => ({ name = "CartDrawer" }) => {
  const { globalStateReducer } = useApp()
  const { cart: cartContext } = useCartContext();
  const { availableFreeGiftProducts } = useCart()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_DRAWER {
      cart: sanityPageCart {
        drawerTitle
        additionalCartDrawerClose
      }
    }
  `)

  const { drawerTitle, additionalCartDrawerClose } = cart || {}

  const [{ activeCart }, dispatch] = globalStateReducer

  const handleClose = () => dispatch({ type: "HIDE_CART" })

  let count = 0

  cartContext?.lines?.map((item: any) => (item?.merchandise?.product?.title.toLowerCase().includes("gift card") ? (count += 1) : (count += item?.quantity)))

  Component.displayName = name
  return (
    <Component
      lineItems={cartContext?.lines}
      count={count}
      active={activeCart}
      handleClose={handleClose}
      drawerTitle={drawerTitle}
      additionalCartDrawerClose={additionalCartDrawerClose}
      availableFreeGiftProducts={availableFreeGiftProducts}
    />
  )
}
